// ignore-string-externalization

const SET_STATE = 'SET_STATE';

export const setState = ({ payload, type }: $TSFixMe) => ({
  type,
  payload,
  meta: {
    type: SET_STATE,
  },
});

const rootState = (state = {}, action: $TSFixMe) => {
  if (action.meta && action.meta.type === SET_STATE) {
    return Object.assign({}, state, action.payload);
  }

  return state;
};

export default rootState;
