// ignore-string-externalization

import React from 'react';
import { usePathname } from 'next/navigation';
import storage from 'local-storage-fallback';
import { sendEvent as gaSendEvent } from './ga';
import { useCurrentUser } from '../../@mrkt/useCurrentUser';
import {
  logGabitoEvent,
  logNonAuthGabitoEvent,
} from '../../../features/src/gabito';
import { createFrodorPageEventNonAuth } from '@spotify-internal/event-definitions/es5/events/createFrodorPageEventNonAuth';
import { createFrodorPageEvent } from '@spotify-internal/event-definitions/es5/events/createFrodorPageEvent';

export function useTracking() {
  const user = useCurrentUser();
  const userRef = React.useRef(user);

  const pathname = usePathname();

  React.useEffect(() => {
    userRef.current = user;
  }, [user]);

  return {
    sendEvent: (opts: any) => {
      sendEvent({
        user: userRef.current,
        pathname: pathname,
        ...opts,
      });
    },

    eventHandler: (eventCategory: string, eventLabel: string) =>
      eventHandler(eventCategory, eventLabel, userRef.current, pathname),

    eventHandlerWithAction: (
      eventCategory: string,
      eventAction: string,
      eventLabel: string,
    ) =>
      eventHandlerWithAction(
        eventCategory,
        eventAction,
        eventLabel,
        userRef.current,
        pathname,
      ),
  };
}

// methods are exported only for legacy code, please use useTracking hook
// for all new code ^^^

export function sendEvent(opts: any) {
  const { eventCategory, eventAction, eventLabel, user } = opts;

  const gaEvent = { eventCategory, eventAction, eventLabel };
  debug('sending GA event', gaEvent);
  gaSendEvent(gaEvent);

  const href = process.browser ? window.location.href : opts.pathname;
  const pathname = process.browser ? window.location.pathname : opts.pathname;

  if (user) {
    let artistId;
    if (user.username) {
      artistId = storage.getItem(
        `${user.username}:analytics:lastVisitedArtist`,
      );
    }

    const gabitoEvent = {
      url: href,
      pathname,
      title: document.title,
      partner_id: user.partnerIds?.google,
      is_employee: null,
      has_access: user.hasAccess,
      artist_id: artistId,
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    };

    debug('sending gabito auth event', gabitoEvent);
    logGabitoEvent(createFrodorPageEvent(gabitoEvent));
  } else {
    const gabitoEvent = {
      url: href,
      pathname,
      title: document.title,
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    };

    debug('sending gabito non-auth event', gabitoEvent);
    logNonAuthGabitoEvent(createFrodorPageEventNonAuth(gabitoEvent));
  }
}

export function eventHandler(
  eventCategory: string,
  eventLabel: string,
  user?: any,
  pathname?: string,
) {
  return (e: any) =>
    sendEvent({
      eventCategory,
      eventAction: e.type,
      eventLabel,
      user,
      pathname,
    });
}

export function eventHandlerWithAction(
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  user?: any,
  pathname?: string,
) {
  return () =>
    sendEvent({ eventCategory, eventAction, eventLabel, user, pathname });
}

function debug(...args: any[]) {
  if (
    process.browser &&
    (window.location.search || '').includes('debug_tracking')
  ) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
}

export { Track } from './Track';
