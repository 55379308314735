import React from 'react';
import {
  FrodorModulesProvider as FrodorModulesProviderBase,
  GetLinkPropsType,
} from '@spotify-internal/frodor-modules';
import { OnLinkClickType } from '@spotify-internal/s4x-masthead';
import { useRouter } from 'next/router';
import { getInternalUrl } from './getInternalUrl';
export const FrodorModulesProvider = ({
  children,
  trackLinkClick,
}: {
  children: React.ReactNode;
  trackLinkClick: (a: OnLinkClickType) => any;
}) => {
  const router = useRouter();
  return (
    <FrodorModulesProviderBase
      preloadVideo="none"
      dir="ltr"
      getLinkProps={({
        href,
        trackingAttributes,
        openInNewTab,
      }: GetLinkPropsType) => {
        const internalUrl = getInternalUrl(href);
        return {
          onClick: () => {
            trackLinkClick({
              trackingAttributes,
              external: openInNewTab,
              href,
            });
            if (!openInNewTab && internalUrl) {
              router.push(internalUrl);
            }
            return {};
          },
          href: internalUrl ? undefined : href,
          target: openInNewTab ? '_blank' : '_self',
        };
      }}
    >
      {children}
    </FrodorModulesProviderBase>
  );
};
