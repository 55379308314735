export const getInternalUrl = (href: string) => {
  if (href.startsWith('/')) {
    return href;
  }
  const currentDomain =
    typeof window !== 'undefined' ? window?.location.hostname : '';
  const url = new URL(href);
  if (
    url.hostname === currentDomain ||
    (process.env.NODE_ENV === 'development' &&
      url.hostname === 'artists.spotify.com')
  ) {
    return url.pathname + url.searchParams;
  }
  return undefined;
};
