// ignore-string-localization

import { createGlobalStyle } from 'styled-components';

const fontDisplay = 'block';

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: spotify-circular;
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Book.woff2') format('woff2'), url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Book.woff')
        format('woff'), url('https://open.scdn.co/fonts/CircularSpUIv3T-Book.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: ${fontDisplay};
  }

  @font-face {
    font-family: spotify-circular;
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Bold.woff2') format('woff2'), url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Bold.woff')
        format('woff'), url('https://open.scdn.co/fonts/CircularSpUIv3T-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: ${fontDisplay};
  }

  @font-face {
    font-family: spotify-circular;
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Black.woff2') format('woff2'), url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Black.woff')
        format('woff'), url('https://open.scdn.co/fonts/CircularSpUIv3T-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: ${fontDisplay};
  }
`;
