// ignore-string-externalization

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import root from './root';

const finalCompose = compose;
// (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose; // eslint-disable-line

const store = (initialState?: $TSFixMe, context?: $TSFixMe) =>
  createStore(
    root,
    initialState,
    finalCompose(applyMiddleware(thunk.withExtraArgument(context))),
  );

export default store;
