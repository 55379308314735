import {
  Banner,
  Text,
  cssColorSet,
  cssColorValue,
} from '@spotify-internal/encore-web';
import { creatorLightTheme } from '@spotify-internal/encore-foundation';
import { formatText } from '../utils';

const Wrapper = styled.div`
  ${cssColorSet(creatorLightTheme.base)};
  z-index: 1000;
  a {
    color: ${cssColorValue('textBrightAccent')};
    text-decoration: underline;
  }
`;

import React from 'react';
import styled from 'styled-components';
const warningText = `WARNING: This page does not have a page ID for UBI tracking set up! This requires an eng ticket, expect ~1 day turnaround time. Details [here](https://backstage.spotify.net/docs/default/component/artists-spotify-com/#landing-pages)`;
export const UbiWarningBanner = ({ onClose }: { onClose: () => void }) => {
  return (
    <Wrapper>
      <Banner colorSet="warning" onClose={onClose}>
        <Text dangerouslySetInnerHTML={{ __html: formatText(warningText) }} />
      </Banner>
    </Wrapper>
  );
};
