import React from 'react';
import { createGlobalStyle as css } from 'styled-components';

const LatinFonts = css`
  @font-face {
    font-family: 'spotify-circular';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Book.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Book.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIv3T-Book.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'spotify-circular';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Bold.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Bold.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIv3T-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'spotify-circular';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Black.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Latin-OS2v3-Black.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIv3T-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

const ArabicFonts = css`
  @font-face {
    font-family: 'circular-spotify-arabic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Light.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Light.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIAraOnly-Light.otf')
        format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-arabic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Book.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Book.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIAraOnly-Book.otf')
        format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-arabic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Bold.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Bold.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIAraOnly-Bold.otf')
        format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-arabic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Black.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Arabic-Black.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIAraOnly-Black.otf')
        format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

const HebrewFonts = css`
  @font-face {
    font-family: 'circular-spotify-hebrew';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Light.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Light.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIHbrOnly-Light.otf')
        format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-hebrew';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Book.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Book.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIHbrOnly-Book.otf')
        format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-hebrew';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Bold.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Bold.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIHbrOnly-Bold.otf')
        format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-hebrew';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Black.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Hebrew-Black.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUIHbrOnly-Black.otf')
        format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

const CyrillicFonts = css`
  @font-face {
    font-family: 'circular-spotify-cyrillic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Light.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Light.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUICyrOnly-Light.otf')
        format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-cyrillic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Book.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Book.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUICyrOnly-Book.otf')
        format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-cyrillic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Bold.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Bold.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUICyrOnly-Bold.otf')
        format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'circular-spotify-cyrillic';
    src: url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Black.woff2')
        format('woff2'),
      url('https://encore.scdn.co/1.2.3/CircularSpotify-UI-Cyrillic-Black.woff')
        format('woff'),
      url('https://open.scdn.co/fonts/CircularSpUICyrOnly-Black.otf')
        format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;

export function Fonts() {
  return (
    <>
      {/** always load all fonts */}
      <LatinFonts />
      <ArabicFonts />
      <HebrewFonts />
      <CyrillicFonts />
    </>
  );
}
