import '@spotify-internal/encore-web/css/fonts.css';
import '@spotify-internal/encore-web/css/encore-text-styles.css';
import '@spotify-internal/encore-foundation/themes/encore-creator-light-theme.css';

/* DO WE NEED THIS? I am not sure if we do, but am not going to delete yet.*/
import '@spotify-internal/encore-web/css/encore-creator-dark-theme.css';
/** */

import '@spotify-internal/encore-web/css/encore-small-devices-layout-theme.css';
import '@spotify-internal/encore-web/css/encore-medium-devices-layout-theme.css';

import { createAppTheme } from '.';

export const AppTheme = createAppTheme({
  light: 'encore-creator-light-theme encore-layout-themes',
  dark: 'encore-creator-dark-theme encore-layout-themes',
});
